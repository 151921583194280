<template>
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.02344 10.5723C1.02344 5.61488 5.09483 1.57227 10.1339 1.57227C15.173 1.57227 19.2444 5.61488 19.2444 10.5723C19.2444 15.5297 15.173 19.5723 10.1339 19.5723C5.09483 19.5723 1.02344 15.5297 1.02344 10.5723Z" fill="white" stroke="#0E3B4D" stroke-width="2"/>
    <rect x="4.74609" y="9.57227" width="10.875" height="2" rx="1" fill="#0E3B4D"/>
  </svg>
</template>

<script>
  export default {
    name: "CircleMinus"
  }
</script>

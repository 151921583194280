<template>
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.2917 2.3724V9.4974H9.33333V13.4557H2.20833V2.3724H13.2917ZM13.2917 0.789062H2.20833C1.3375 0.789062 0.625 1.50156 0.625 2.3724V13.4557C0.625 14.3266 1.3375 15.0391 2.20833 15.0391H10.125L14.875 10.2891V2.3724C14.875 1.50156 14.1625 0.789062 13.2917 0.789062ZM7.75 9.4974H3.79167V7.91406H7.75V9.4974ZM11.7083 6.33073H3.79167V4.7474H11.7083V6.33073Z" fill="#0E3B4D"/>
  </svg>
</template>

<script>
export default {
  name: "DocumentIcon"
}
</script>

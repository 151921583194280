<template>
  <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="17.4058" cy="17.3745" r="17.1841" fill="#0AA309"/>
    <path d="M8.26953 17.1168L13.8083 22.6556L26.5452 9.91895" stroke="white" stroke-width="3"/>
  </svg>
</template>

<script>
export default {
  name: "CheckCircleIcon"
}
</script>

<script>
  export default {
    name: 'IconPageLast'
  }
</script>

<template>
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.945166 2.19193L4.18683 5.43359L0.945166 8.67526C0.620166 9.00026 0.620166 9.52526 0.945166 9.85026C1.27017 10.1753 1.79517 10.1753 2.12017 9.85026L5.94517 6.02526C6.27017 5.70026 6.27017 5.17526 5.94517 4.85026L2.12017 1.01693C1.79517 0.691927 1.27017 0.691927 0.945166 1.01693C0.628499 1.34193 0.628499 1.87526 0.945166 2.19193ZM9.87016 0.433594C10.3285 0.433594 10.7035 0.808594 10.7035 1.26693V9.60026C10.7035 10.0586 10.3285 10.4336 9.87016 10.4336C9.41183 10.4336 9.03683 10.0586 9.03683 9.60026V1.26693C9.03683 0.808594 9.41183 0.433594 9.87016 0.433594Z" fill="#495057"/>
  </svg>
</template>

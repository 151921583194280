<script>
export default {
  name: 'IconRocket'
}
</script>

<template>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 297 271" style="width: 220px;" xml:space="preserve" fill="#7c7c7d">
  <g>
	  <path d="M280.7,257.8C280.7,257.8,280.7,257.8,280.7,257.8l-42,0.1c2.4-2.5,4.3-6,5.1-11.1c1.9-16.3-11.6-23.9-20.1-26
      c-3-6.3-11.1-15.9-24.5-14.1c-4.2,0.5-7.7,1.6-10.5,2.9c-0.5-1.9-2.4-7.3-9.8-10.8c-4.9-2.3-9.3-2.5-12.2-2.2
      c2.6-8.4,2-16.3,1.4-20.5l3.9,0l0.7-1.3c0.3-0.5,2.9-5.3,6-13.1c3.1,4.8,7.8,14,6,23.7l-0.8,4l3.9-1.2c23-6.8,24.1-35,24.1-36.2
      l0-0.4l-2-5.9c-3.8-11.4-10.3-21.9-18.9-30.6c-0.2-0.2-0.3-0.3-0.5-0.4c0.2-2.2,0.4-4.4,0.4-6.6c0.8-33.2-10.4-57.5-19.8-72.1
      c-10.3-15.8-20.7-23.7-21.1-24c0,0-0.1,0-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2-0.1-0.2-0.1
      c-0.1,0-0.2,0-0.2-0.1c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.2,0.1
      c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0.1-0.2,0.1c0,0-0.1,0-0.1,0.1c-0.4,0.3-10.8,8.2-21,24.1
      c-9.4,14.6-20.5,39-19.6,72.2c0.1,2.2,0.3,4.4,0.5,6.5c-0.2,0.1-0.3,0.2-0.5,0.4c-8.6,8.7-15.1,19.3-18.8,30.7l-1.9,5.9l0,0.4
      c0,1.2,1.2,29.4,24.2,36.2l3.9,1.2l-0.8-4c-1.9-9.6,2.8-18.9,5.9-23.7c3.2,7.8,5.8,12.6,6.1,13.1l0.7,1.3l3.9,0
      c-0.6,4.1-1.2,12.1,1.5,20.5c-2.9-0.3-7.3-0.1-12.2,2.3c-7.4,3.5-9.3,8.9-9.8,10.8c-2.8-1.4-6.3-2.4-10.5-2.9
      c-13.4-1.7-21.6,7.9-24.5,14.2c-8.5,2.1-21.9,9.8-20,26.1c0.8,5,2.7,8.5,5.1,11l-42,0.1c-1.4,0-2.5,1.1-2.5,2.5
      c0,1.4,1.1,2.5,2.5,2.5c0,0,0,0,0,0l53.2-0.1c0.1,0,0.2,0,0.4,0c0.2,0,0.4,0,0.6,0l78-0.1c0,0,0,0,0,0l77.1-0.1c0,0,0,0,0.1,0
      c0.2,0,0.4,0,0.6,0c0.2,0,0.5,0,0.7,0l52.9-0.1c1.4,0,2.5-1.1,2.5-2.5C283.2,258.9,282.1,257.8,280.7,257.8z M205.2,147.3l1.7,5.1
      c-0.2,3.1-2.1,22.7-16.9,29.8c0.5-12.2-6.8-22.8-9.2-26c3.5-9.4,7.1-21.6,9-35.1C196.8,128.7,202.1,137.7,205.2,147.3z M148.6,17.2
      c4,3.5,15.4,14.3,24.6,32.4c-5.4-2.7-13.9-5.9-24.5-5.5c-10.6-0.3-19.1,2.8-24.5,5.6C133.2,31.6,144.5,20.7,148.6,17.2z
      M120.4,57.8c0.2-0.1,0.4-0.2,0.5-0.3c0.1-0.1,10.9-8.7,26.9-8.4c0.2,0,0.3,0,0.5,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0
      c0.2,0,0.3,0,0.5,0c16-0.3,26.8,8.2,27,8.4c0.2,0.1,0.4,0.2,0.6,0.3c5.6,13.5,9.6,30.2,9.1,50.2c-0.7,17.4-5.3,33.8-9.6,45.7
      c-3.9-2.8-11.9-6.3-27.4-6.3c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c-15.7,0-23.7,3.6-27.6,6.4c-4.4-11.9-9-28.2-9.8-45.6
      C110.9,88.1,114.8,71.3,120.4,57.8z M107.6,182.2c-14.8-7-16.8-26.6-17-29.7l1.7-5.1c3.1-9.6,8.4-18.6,15.3-26.3
      c1.9,13.5,5.6,25.7,9.1,35.1C114.3,159.5,107.1,170,107.6,182.2z M123.1,158.7c2.2-1.9,8.9-6.3,25.7-6.3c0,0,0.1,0,0.1,0
      c16.7,0,23.4,4.3,25.6,6.2c-2.4,6.1-4.5,10.5-5.6,12.6l-20.1,0l-20.1,0C127.6,169.2,125.5,164.8,123.1,158.7z M149,192
      c-7.4-5.6-5.7-14.5-5.4-15.8l5.2,0l5.5,0C154.6,177.5,156.4,186.4,149,192z M138.5,176.2c-0.9,5.4-0.1,15.1,9,20.8
      c0.4,0.3,0.9,0.4,1.3,0.4c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.5,0,0.9-0.1,1.3-0.4c9-5.7,9.9-15.4,8.9-20.9l3.6,0
      c1.1,5.6,3.2,24.5-14.1,36.2c-17.4-11.7-15.3-30.5-14.3-36.1L138.5,176.2z M59.1,246.5c-2-16.8,16.5-20.7,17.3-20.8l1.4-0.3
      l0.5-1.3c0.1-0.1,5.6-14.1,19.7-12.3c19.6,2.4,18.8,17.4,18.7,18.1c-0.1,1.4,0.9,2.6,2.3,2.7c1.4,0.1,2.6-0.9,2.7-2.3
      c0-0.1,0.8-10.9-8.3-18c0.3-0.3,0.5-0.8,0.6-1.2c0-0.2,0.8-4.7,7.1-7.6c6.6-3.1,11.8-1.5,12-1.4c0.1,0,0.2,0,0.3,0.1
      c2.7,5.4,7.1,10.7,13.9,15c0.4,0.3,0.9,0.4,1.3,0.4c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0c0.5,0,0.9-0.1,1.3-0.4
      c6.8-4.3,11.2-9.7,13.9-15.1c0.1,0,0.2,0,0.3-0.1c0.2-0.1,5.4-1.8,12,1.3c6.3,3,7.1,7.4,7.1,7.6c0.1,0.5,0.3,0.9,0.6,1.3
      c-9.1,7-8.3,17.8-8.3,18c0.1,1.4,1.3,2.4,2.7,2.3c1.4-0.1,2.4-1.3,2.3-2.7c-0.1-0.6-1.1-15.6,18.7-18.1c14-1.8,19.6,11.7,19.8,12.2
      l0.5,1.3l1.4,0.3c0.8,0.2,19.3,4,17.4,20.7c-1.6,10.2-8.6,11.6-11.2,11.7l-79.1,0.1c0,0,0,0,0,0l-78.1,0.1
      C68.1,258,60.7,256.9,59.1,246.5z"/>
	  <path d="M148.7,100.7C148.7,100.7,148.7,100.7,148.7,100.7c4.8,0,9.3-1.8,12.7-5c3.4-3.2,5.3-7.6,5.3-12.2c0-9.4-8.1-17.1-18-17.1
      c0,0,0,0,0,0c-4.8,0-9.3,1.8-12.6,5c-3.4,3.2-5.3,7.6-5.3,12.2C130.7,93.1,138.8,100.7,148.7,100.7z M139.4,75.1
      c2.4-2.3,5.7-3.6,9.2-3.6c0,0,0,0,0,0c7.1,0,12.9,5.4,13,12.1c0,3.2-1.3,6.3-3.7,8.6c-2.4,2.3-5.7,3.6-9.2,3.6c0,0,0,0,0,0
      c-7.1,0-12.9-5.4-13-12.1C135.7,80.4,137,77.4,139.4,75.1z"/>
    <path d="M148.7,119.2C148.7,119.2,148.7,119.2,148.7,119.2c-4.4,0-7.9,3.8-7.9,8.4c0,4.6,3.6,8.3,7.9,8.3c0,0,0,0,0,0
      c2.2,0,4.2-0.9,5.7-2.5c1.4-1.6,2.2-3.6,2.2-5.8C156.6,122.9,153.1,119.2,148.7,119.2z M151.6,127.5c0,0.9-0.3,1.8-0.9,2.4
      c-0.5,0.6-1.2,0.9-2,0.9c0,0,0,0,0,0c-1.6,0-2.9-1.5-2.9-3.4c0-1.9,1.3-3.4,2.9-3.4c0,0,0,0,0,0
      C150.3,124.2,151.6,125.7,151.6,127.5L151.6,127.5z"/>
  </g>
</svg>
</template>

<template>
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.4167 9.97331H9.75833L9.525 9.74831C10.3417 8.79831 10.8333 7.56497 10.8333 6.22331C10.8333 3.23164 8.40833 0.806641 5.41667 0.806641C2.425 0.806641 0 3.23164 0 6.22331C0 9.21497 2.425 11.64 5.41667 11.64C6.75833 11.64 7.99167 11.1483 8.94167 10.3316L9.16667 10.565V11.2233L13.3333 15.3816L14.575 14.14L10.4167 9.97331ZM5.41667 9.97331C3.34167 9.97331 1.66667 8.29831 1.66667 6.22331C1.66667 4.14831 3.34167 2.47331 5.41667 2.47331C7.49167 2.47331 9.16667 4.14831 9.16667 6.22331C9.16667 8.29831 7.49167 9.97331 5.41667 9.97331Z" fill="#212429"/>
  </svg>
</template>

<script>
export default {
  name: "SearchIcon"
}
</script>

<script>
  export default {
    name: 'IconPagePrev'
  }
</script>

<template>
  <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.96201 1.025C5.63701 0.7 5.11201 0.7 4.78701 1.025L0.962012 4.85C0.637012 5.175 0.637012 5.7 0.962012 6.025L4.78701 9.85C5.11201 10.175 5.63701 10.175 5.96201 9.85C6.28701 9.525 6.28701 9 5.96201 8.675L2.72868 5.43333L5.96201 2.2C6.28701 1.875 6.27868 1.34167 5.96201 1.025Z" fill="#495057"/>
  </svg>
</template>

<template>
  <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.67878 2.30664H16.6788C17.2288 2.30664 17.6788 1.85664 17.6788 1.30664C17.6788 0.756641 17.2288 0.306641 16.6788 0.306641H2.67878C2.12878 0.306641 1.67878 0.756641 1.67878 1.30664C1.67878 1.85664 2.12878 2.30664 2.67878 2.30664ZM17.8388 4.10664C17.7488 3.64664 17.3388 3.30664 16.8588 3.30664H2.49878C2.01878 3.30664 1.60878 3.64664 1.51878 4.10664L0.518785 9.10664C0.398785 9.72664 0.868785 10.3066 1.49878 10.3066H1.67878V15.3066C1.67878 15.8566 2.12878 16.3066 2.67878 16.3066H10.6788C11.2288 16.3066 11.6788 15.8566 11.6788 15.3066V10.3066H15.6788V15.3066C15.6788 15.8566 16.1288 16.3066 16.6788 16.3066C17.2288 16.3066 17.6788 15.8566 17.6788 15.3066V10.3066H17.8588C18.4888 10.3066 18.9588 9.72664 18.8388 9.10664L17.8388 4.10664ZM9.67878 14.3066H3.67878V10.3066H9.67878V14.3066Z" fill="#0E3B4D"/>
  </svg>
</template>

<script>
export default {
  name: "StoreLarge"
}
</script>

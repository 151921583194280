<script>
  export default {
    name: 'IconPageFirst'
  }
</script>

<template>
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.4621 8.67526L7.22039 5.43359L10.4621 2.19193C10.7871 1.86693 10.7871 1.34193 10.4621 1.01693C10.1371 0.691926 9.61206 0.691926 9.28706 1.01693L5.46206 4.84193C5.13706 5.16693 5.13706 5.69193 5.46206 6.01693L9.28706 9.85026C9.61206 10.1753 10.1371 10.1753 10.4621 9.85026C10.7787 9.52526 10.7787 8.99193 10.4621 8.67526ZM1.53706 10.4336C1.07873 10.4336 0.703728 10.0586 0.703728 9.60026L0.703729 1.26692C0.703729 0.808591 1.07873 0.433591 1.53706 0.433591C1.9954 0.433591 2.3704 0.808591 2.3704 1.26693L2.37039 9.60026C2.37039 10.0586 1.99539 10.4336 1.53706 10.4336Z" fill="#495057"/>
  </svg>
</template>

<template>
  <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5638 5.72852V14.8952H3.23047V5.72852H10.5638ZM9.1888 0.228516H4.60547L3.6888 1.14518H0.480469V2.97852H13.3138V1.14518H10.1055L9.1888 0.228516ZM12.3971 3.89518H1.39714V14.8952C1.39714 15.9035 2.22214 16.7285 3.23047 16.7285H10.5638C11.5721 16.7285 12.3971 15.9035 12.3971 14.8952V3.89518Z" fill="#0E3B4D"/>
  </svg>
</template>

<script>
export default {
  name: "TrashIcon"
}
</script>

<template>
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.60751 1.88167H11.0331C11.4034 1.88167 11.7064 1.57871 11.7064 1.20841C11.7064 0.838122 11.4034 0.535156 11.0331 0.535156H1.60751C1.23722 0.535156 0.934251 0.838122 0.934251 1.20841C0.934251 1.57871 1.23722 1.88167 1.60751 1.88167ZM11.8141 3.09354C11.7535 2.78384 11.4775 2.55493 11.1543 2.55493H1.48632C1.16316 2.55493 0.887123 2.78384 0.82653 3.09354L0.153272 6.45982C0.0724809 6.87724 0.388912 7.26773 0.813064 7.26773H0.934251V10.634C0.934251 11.0043 1.23722 11.3073 1.60751 11.3073H6.99357C7.36386 11.3073 7.66683 11.0043 7.66683 10.634V7.26773H10.3599V10.634C10.3599 11.0043 10.6628 11.3073 11.0331 11.3073C11.4034 11.3073 11.7064 11.0043 11.7064 10.634V7.26773H11.8276C12.2517 7.26773 12.5681 6.87724 12.4874 6.45982L11.8141 3.09354ZM6.32031 9.96076H2.28077V7.26773H6.32031V9.96076Z" fill="#0E3B4D"/>
  </svg>
</template>

<script>
export default {
  name: 'Store'
}
</script>

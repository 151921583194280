<template>
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.08389 1.97396C2.75055 4.14062 5.83389 8.14062 5.83389 8.14062V13.1406C5.83389 13.5573 6.16722 13.974 6.66722 13.974H8.33389C8.83389 13.974 9.16722 13.5573 9.16722 13.1406V8.14062C9.16722 8.14062 12.2506 4.14062 13.9172 1.97396C14.4172 1.39062 14.0006 0.640625 13.3339 0.640625H1.66722C1.00055 0.640625 0.583886 1.39062 1.08389 1.97396Z" fill="#858585"/>
  </svg>
</template>

<script>
export default {
  name: "IconFilter"
}
</script>

<template>
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.80642 15.3051L0.378229 0.674558L17.2627 0.690794L8.80642 15.3051Z" fill="white"/>
  </svg>
</template>

<script>
  export default {
    name: "SelectArrow"
  }
</script>

<script>
  export default {
    name: 'IconPageNext'
  }
</script>

<template>
  <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.445216 9.84219C0.770216 10.1672 1.29522 10.1672 1.62022 9.84219L5.44522 6.01719C5.77022 5.69219 5.77022 5.16719 5.44522 4.84219L1.62022 1.01719C1.29522 0.692188 0.770216 0.692188 0.445216 1.01719C0.120217 1.34219 0.120216 1.86719 0.445216 2.19219L3.67855 5.43385L0.445216 8.66719C0.120216 8.99219 0.128549 9.52552 0.445216 9.84219Z" fill="#495057"/>
  </svg>
</template>

<template>
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.5 0.306641C3.05 0.306641 0.25 3.10664 0.25 6.55664C0.25 10.0066 3.05 12.8066 6.5 12.8066C9.95 12.8066 12.75 10.0066 12.75 6.55664C12.75 3.10664 9.95 0.306641 6.5 0.306641ZM6.5 11.5566C3.74375 11.5566 1.5 9.31289 1.5 6.55664C1.5 3.80039 3.74375 1.55664 6.5 1.55664C9.25625 1.55664 11.5 3.80039 11.5 6.55664C11.5 9.31289 9.25625 11.5566 6.5 11.5566ZM6.69375 6.01914C5.5875 5.73789 5.23125 5.43164 5.23125 4.97539C5.23125 4.45039 5.725 4.08164 6.54375 4.08164C7.40625 4.08164 7.73125 4.49414 7.75625 5.10664H8.825C8.79375 4.26914 8.28125 3.50039 7.26875 3.25039V2.18164H5.8125V3.23789C4.86875 3.43789 4.1125 4.05039 4.1125 4.99414C4.1125 6.11289 5.04375 6.67539 6.4 7.00039C7.61875 7.28789 7.8625 7.71914 7.8625 8.16914C7.8625 8.50039 7.61875 9.03789 6.55 9.03789C5.55 9.03789 5.15625 8.58789 5.1 8.01289H4.025C4.0875 9.07539 4.875 9.67539 5.8125 9.86914V10.9316H7.275V9.88789C8.225 9.70664 8.975 9.16289 8.98125 8.15664C8.975 6.78164 7.79375 6.30664 6.69375 6.01914V6.01914Z" fill="#0E3B4D"/>
  </svg>
</template>

<script>
export default {
  name: "DollarIcon"
}
</script>

<template>
<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11.7332 0.472656H1.59883C0.96543 0.472656 0.332031 1.04271 0.332031 1.73945V3.64598C0.332031 4.10203 0.604393 4.49474 0.96543 4.71643V11.8738C0.96543 12.5706 1.66217 13.1406 2.23223 13.1406H11.0998C11.6699 13.1406 12.3666 12.5706 12.3666 11.8738V4.71643C12.7276 4.49474 13 4.10203 13 3.64598V1.73945C13 1.04271 12.3666 0.472656 11.7332 0.472656ZM11.0998 11.8738H2.23223V4.90645H11.0998V11.8738ZM11.7332 3.63965H1.59883V1.73945H11.7332V3.63965Z" fill="#0E3B4D"/>
  <path d="M8.56992 6.80469H4.76953V8.07148H8.56992V6.80469Z" fill="#0E3B4D"/>
</svg>
</template>

<script>
export default {
  name: 'OrdersBtn'
}
</script>

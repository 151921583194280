<template>
  <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.0441 0.806641C5.46309 0.806641 0.933594 5.28664 0.933594 10.8066C0.933594 16.3266 5.46309 20.8066 11.0441 20.8066C16.625 20.8066 21.1545 16.3266 21.1545 10.8066C21.1545 5.28664 16.625 0.806641 11.0441 0.806641ZM15.0883 11.8066H12.0551V14.8066C12.0551 15.3566 11.6001 15.8066 11.0441 15.8066C10.488 15.8066 10.033 15.3566 10.033 14.8066V11.8066H6.99988C6.4438 11.8066 5.98883 11.3566 5.98883 10.8066C5.98883 10.2566 6.4438 9.80664 6.99988 9.80664H10.033V6.80664C10.033 6.25664 10.488 5.80664 11.0441 5.80664C11.6001 5.80664 12.0551 6.25664 12.0551 6.80664V9.80664H15.0883C15.6443 9.80664 16.0993 10.2566 16.0993 10.8066C16.0993 11.3566 15.6443 11.8066 15.0883 11.8066Z" fill="#0E3B4D"/>
  </svg>
</template>

<script>
export default {
  name: "PlusCircle"
}
</script>

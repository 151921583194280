<template>
  <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3087_17120)">
      <path d="M13.2565 15.1139L13.2565 7.98893L9.92318 7.98893L9.92318 4.0306L3.92318 4.0306L3.92318 15.1139L13.2565 15.1139ZM13.2565 16.6973L3.92318 16.6973C3.18984 16.6973 2.58984 15.9848 2.58984 15.1139L2.58984 4.0306C2.58984 3.15976 3.18984 2.44726 3.92318 2.44726L10.5898 2.44726L14.5898 7.19727L14.5898 15.1139C14.5898 15.9848 13.9898 16.6973 13.2565 16.6973ZM8.58984 7.98893L5.25651 7.98893L5.25651 9.57227L8.58984 9.57227L8.58984 7.98893ZM11.9232 11.1556L5.25651 11.1556L5.25651 12.7389L11.9232 12.7389L11.9232 11.1556Z" fill="#0E3B4D"/>
    </g>
    <defs>
      <clipPath id="clip0_3087_17120">
        <rect width="16" height="19" fill="white" transform="matrix(1 8.74228e-08 8.74228e-08 -1 0.589844 19.0723)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "NoteIcon"
}
</script>

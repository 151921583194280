<template>
  <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.0286 0.976562C8.59865 0.976562 0.945312 8.44323 0.945312 17.6432C0.945312 26.8432 8.59865 34.3099 18.0286 34.3099C27.4586 34.3099 35.112 26.8432 35.112 17.6432C35.112 8.44323 27.4586 0.976562 18.0286 0.976562ZM18.0286 30.9766C10.4949 30.9766 4.36198 24.9932 4.36198 17.6432C4.36198 10.2932 10.4949 4.3099 18.0286 4.3099C25.5624 4.3099 31.6953 10.2932 31.6953 17.6432C31.6953 24.9932 25.5624 30.9766 18.0286 30.9766ZM18.5582 16.2099C15.5345 15.4599 14.5607 14.6432 14.5607 13.4266C14.5607 12.0266 15.9103 11.0432 18.1482 11.0432C20.5057 11.0432 21.3941 12.1432 21.4624 13.7766H24.3836C24.2982 11.5432 22.8974 9.49323 20.1299 8.82656V5.97656H16.1495V8.79323C13.5699 9.32656 11.5028 10.9599 11.5028 13.4766C11.5028 16.4599 14.0482 17.9599 17.7553 18.8266C21.0866 19.5932 21.7528 20.7432 21.7528 21.9432C21.7528 22.8266 21.0866 24.2599 18.1653 24.2599C15.432 24.2599 14.3557 23.0599 14.202 21.5266H11.2636C11.4345 24.3599 13.587 25.9599 16.1495 26.4766V29.3099H20.147V26.5266C22.7436 26.0432 24.7936 24.5932 24.8107 21.9099C24.7936 18.2432 21.5649 16.9766 18.5582 16.2099V16.2099Z" fill="#0E3B4D"/>
  </svg>
</template>

<script>
  export default {
    name: "DollarIconLarge"
  }
</script>

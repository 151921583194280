<template>
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.8722 0.697266C5.29121 0.697266 0.761719 5.17727 0.761719 10.6973C0.761719 16.2173 5.29121 20.6973 10.8722 20.6973C16.4532 20.6973 20.9827 16.2173 20.9827 10.6973C20.9827 5.17727 16.4532 0.697266 10.8722 0.697266ZM14.9164 11.6973H11.8832V14.6973C11.8832 15.2473 11.4283 15.6973 10.8722 15.6973C10.3161 15.6973 9.86115 15.2473 9.86115 14.6973V11.6973H6.828C6.27193 11.6973 5.81696 11.2473 5.81696 10.6973C5.81696 10.1473 6.27193 9.69727 6.828 9.69727H9.86115V6.69727C9.86115 6.14727 10.3161 5.69727 10.8722 5.69727C11.4283 5.69727 11.8832 6.14727 11.8832 6.69727V9.69727H14.9164C15.4725 9.69727 15.9274 10.1473 15.9274 10.6973C15.9274 11.2473 15.4725 11.6973 14.9164 11.6973Z" fill="#0E3B4D"/>
  </svg>
</template>

<script>
  export default {
    name: "CirclePlus"
  }
</script>

<template>
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.41667 8.39063H6.25V10.224H4.41667V8.39063ZM17.25 3.80729V16.6406C17.25 17.649 16.425 18.474 15.4167 18.474H2.58333C1.56583 18.474 0.75 17.649 0.75 16.6406L0.759167 3.80729C0.759167 2.79896 1.56583 1.97396 2.58333 1.97396H3.5V0.140625H5.33333V1.97396H12.6667V0.140625H14.5V1.97396H15.4167C16.425 1.97396 17.25 2.79896 17.25 3.80729ZM2.58333 5.64063H15.4167V3.80729H2.58333V5.64063ZM15.4167 16.6406V7.47396H2.58333V16.6406H15.4167ZM11.75 10.224H13.5833V8.39063H11.75V10.224ZM8.08333 10.224H9.91667V8.39063H8.08333V10.224Z" fill="#0E3B4D"/>
  </svg>
</template>

<script>
export default {
  name: "CalendarIcon"
}
</script>

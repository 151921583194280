<template>
  <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20">
    <path d="M10 16.667 3.333 10 10 3.333 11.229 4.562 6.667 9.125H16.667V10.875H6.667L11.229 15.438Z"/>
  </svg>
</template>

<script>
export default {
  name: 'ArrowBack'
}
</script>

<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2.30664C6.47 2.30664 2 6.77664 2 12.3066C2 17.8366 6.47 22.3066 12 22.3066C17.53 22.3066 22 17.8366 22 12.3066C22 6.77664 17.53 2.30664 12 2.30664ZM12 20.3066C7.59 20.3066 4 16.7166 4 12.3066C4 7.89664 7.59 4.30664 12 4.30664C16.41 4.30664 20 7.89664 20 12.3066C20 16.7166 16.41 20.3066 12 20.3066ZM15.59 7.30664L12 10.8966L8.41 7.30664L7 8.71664L10.59 12.3066L7 15.8966L8.41 17.3066L12 13.7166L15.59 17.3066L17 15.8966L13.41 12.3066L17 8.71664L15.59 7.30664Z" fill="#0E3B4D"/>
  </svg>
</template>

<script>
export default {
  name: 'CloseCircle'
}
</script>

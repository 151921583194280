<template>
  <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.19447 9.30664L-0.00167847 0.306641L10.3906 0.306641L5.19447 9.30664Z" fill="#0E3B4D"/>
  </svg>
</template>

<script>
export default {
  name: "ChevronDown"
}
</script>

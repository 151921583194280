<script>
  export default {
    name: 'IconChevronDown'
  }
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4661_8504)">
      <path d="M7 10L12 15L17 10H7Z" fill="#525252"/>
    </g>
    <defs>
      <clipPath id="clip0_4661_8504">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
